.request {
	font-size: 2rem;
	text-align: center;
	color: white;
	margin: 5vh 5vw 0 5vw;
}

.adjustment {
	margin-bottom: 10vh;
}
