.wrapper {
	width: 100%;
	margin-top:0px;
}
.img {
	background-image: url("https://mcgovern.mit.edu/wp-content/uploads/2019/02/huntington_1920x1080.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	margin-top:0px;
}

.faintimg {
	background-color: rgba(26, 36, 52, 0.6);
	margin-top:0px;
	padding: 7vh;
}
.title {
	color: white;
	font-size: 5.5rem;
    line-height: 1;
	text-align: center;
	margin-top:0px;
	margin-bottom:0px;
}
.divider {
	width: 30%;
	height: 1px;
	border: 1px solid var(--color-gray);
	margin: 20px auto 20px auto;
}
.subtitlebanner {
	color: var(--light-slate);
	font-size: 2rem;
    line-height: 1;
	text-align: center;
	margin-top:0px;
	margin-bottom:0px;
}