:root {

  --dark-navy: #020c1b;
  --navy: #0a192f;
  --light-navy: #112240;
  --lightest-navy: #233554;
  --navy-shadow: rgba(2,12,27,0.7);
  --dark-slate: #495670;
  --slate: #8892b0;
  --light-slate: #a8b2d1;
  --lightest-slate: #ccd6f6;
  --white: #e6f1ff;
  --green: #64ffda;
  --green-tint: rgba(100,255,218,0.1);
  --pink: #f57dff;
  --blue: #57cbff;

  --color-dark: #1C1D20;
  --color-dark-dark: #141517;
  --color-light: #FFFFFF;
  --color-blue: #455CE9;
  --color-blue-dark: #334BD3;
  --color-gray: #999D9E;
  --color-lightgray: #E9EAEB;
  --color-white: #FFFFFF;
  
  --color-border: rgba(28, 29, 32, 0.175);
  --color-border-solid: #D2D2D2;
  --color-border-light: rgba(255, 255, 255, 0.2);
  --color-border-solid-light: #545557;

  --color-text: #1C1D20;
  --color-text-light: #FFF;

  --alert-error: #ff4444;
  --alert-success: #24C958;

  --animation-primary: all .5s cubic-bezier(.7, 0, .3, 1);
  --animation-fast: all .3s cubic-bezier(.7, 0, .3, 1);
  --animation-smooth: all .7s cubic-bezier(.7, 0, .3, 1);
  --animation-slow: all .9s cubic-bezier(.7, 0, .3, 1);

  --section-padding: clamp(5em, 21vh, 12em);
  --container-padding: clamp(2.5em, 8vw, 8em);
  --gap-padding: clamp(1.5em, 4vw, 2.5em);

  --green-tint: rgba(100,255,218,0.1);

  --font-sans: 'Calibre', 'Inter', 'San Francisco', 'SF Pro Text', -apple-system, system-ui,
  sans-serif;
  --font-mono: 'SF Mono', 'Fira Code', 'Fira Mono', 'Roboto Mono', monospace;

  --font-sans: "Calibre","Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
  --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
  --fz-xxs: 12px;
  --fz-xs: 13px;
  --fz-sm: 14px;
  --fz-md: 16px;
  --fz-lg: 18px;
  --fz-xl: 20px;
  --fz-xxl: 22px;
  --fz-heading: 32px;
  --border-radius: 4px;
  --nav-height: 100px;
  --nav-scroll-height: 70px;
  --tab-height: 42px;
  --tab-width: 120px;
  --easing: cubic-bezier(0.645,0.045,0.355,1);
  --transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
  --hamburger-width: 30px;
  --ham-before: top 0.1s ease-in 0.25s,opacity 0.1s ease-in;
  --ham-before-active: top 0.1s ease-out,opacity 0.1s ease-out 0.12s;
  --ham-after: bottom 0.1s ease-in 0.25s,transform 0.22s cubic-bezier(0.55,0.055,0.675,0.19);
  --ham-after-active: bottom 0.1s ease-out,transform 0.22s cubic-bezier(0.215,0.61,0.355,1) 0.12s;

}
